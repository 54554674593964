import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap"
import { Link } from "react-router-dom"

//import action
import { getChartsData as onGetChartsData } from "../../store/actions"

// Pages Components
import WelcomeComp from "./WelcomeComp"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import { getAuthUser } from "helpers/token_helper"
import CenterCapacity from "./CenterCapacity"
import Attendance from "./Attendance"
import LiveRoomStatus from "./LiveRoomStatus"
import Messages from "./Messages"
import SignInOut from "./SignInOut"
import Accounting from "./Accounting"
import Birthdays from "./Birthdays"
import Enrolled from "./Enrolled"
import Summary from "./Summary"
import { getAdultVisibility, getStaffVisibility } from "helpers/token_helper"
import { EnumRoleTypes } from "helpers/enum_helper"

const Dashboard = props => {
  const dispatch = useDispatch()
  const [profile, setProfile] = useState({})
  const [isAccountant, setIsAccountant] = useState(false)
  const [isAdminOrOwner, setIsAdminOrOwner] = useState(false)

  //////////////////BASIC SETTING STATE/////////////////////
  const { basicSetting } = useSelector(state => state.Layout)
  //////////////////////////////////////

  useEffect(() => {
    if (getAuthUser()) {
      setProfile(getAuthUser())
    }

    dispatch(onGetChartsData(1))

    // Check if the user is an Accountant
    if (getStaffVisibility([EnumRoleTypes.Accountant])) {
      setIsAccountant(true)
    }

    // Check if the user is Center_Owner or Center_Director
    if (
      getStaffVisibility([
        EnumRoleTypes.Center_Owner,
        EnumRoleTypes.Center_Director,
      ])
    ) {
      setIsAdminOrOwner(true)
    }
  }, [dispatch])

  //meta title
  document.title =
    "Dashboard | " + (basicSetting?.SiteName || process.env.REACT_APP_NAME)

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("Home")}
            breadcrumbItem={props.t("Dashboard")}
          />
          {isAdminOrOwner && (
            <>
              <Row>
                <Col xl="4">
                  <WelcomeComp profile={profile} />
                </Col>
                <Col xl="8">
                  <Summary />
                </Col>
              </Row>
              <Row>
                <Col xl="4">
                  <SignInOut />
                </Col>
                <Col xl="8">
                  <Accounting />
                </Col>
              </Row>
              <Row>
                <Col xl="4">
                  <CenterCapacity dataColors='["--bs-primary", "--bs-success", "--bs-danger"]' />
                </Col>
                <Col xl="8">
                  <Attendance />
                </Col>
              </Row>
              <Row>
                <Col xl="4">
                  <LiveRoomStatus />
                </Col>
                <Col xl="4">
                  <Enrolled />
                </Col>
                <Col xl="4">
                  <Birthdays />
                </Col>
              </Row>
              <Row>
                <Col lg="12">
                  <Messages />
                </Col>
              </Row>
            </>
          )}
          {isAccountant && (
            <>
              <Row>
                <Col xl="4">
                  <WelcomeComp profile={profile} />
                </Col>
                <Col xl="8">
                  <Accounting />
                </Col>
              </Row>
            </>
          )}
          {!isAdminOrOwner && !isAccountant && (
            <>
              <Row>
                <Col xl="4">
                  <WelcomeComp profile={profile} />
                </Col>
                <Col xl="8"></Col>
              </Row>
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(Dashboard)
