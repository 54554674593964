import { HandleValidDate, FormatDate } from "helpers/method_helper"
import React from "react"
import { Link } from "react-router-dom"
import { Row, Col, UncontrolledTooltip } from "reactstrap"
import ProfilePicture from "components/Common/ProfilePicture"
import { Calculate_Age, FormatDateDDMMMYYYY } from "helpers/method_helper"
import { EnumObjectTypes } from "helpers/enum_helper"
import Moment from "moment"
import { getHoursMinutes } from "helpers/duration_helper"

const ChildrenDailyColumnsForExport = [
  {
    Header: "FirstName",
    accessor: "FirstName",
  },
  {
    Header: "LastName",
    accessor: "LastName",
  },
  {
    Header: "Gender",
    accessor: "Gender",
  },
  {
    Header: "DateOfBirth",
    accessor: row =>
      row.DateOfBirth ? FormatDateDDMMMYYYY(row.DateOfBirth) : "",
  },
  {
    Header: "StartDate",
    accessor: row => (row.StartDate ? FormatDateDDMMMYYYY(row.StartDate) : ""),
  },
  {
    Header: "EndDate",
    accessor: row => (row.EndDate ? FormatDateDDMMMYYYY(row.EndDate) : ""),
  },
  {
    Header: "CountryName",
    accessor: "CountryName",
  },
  {
    Header: "StateName",
    accessor: "StateName",
  },
  {
    Header: "CityName",
    accessor: "CityName",
  },
  {
    Header: "Address",
    accessor: "Address",
  },
  {
    Header: "ZipCode",
    accessor: "ZipCode",
  },
  {
    Header: "MealType",
    accessor: "MealType",
  },
  {
    Header: "Class",
    accessor: "ClassTitle",
  },
  {
    Header: "Check In",
    accessor: "CheckIn",
  },
  {
    Header: "Check Out",
    accessor: "CheckOut",
  },
]

const ChildrenWeeklyColumnsForExport = [
  {
    Header: "FirstName",
    accessor: "FirstName",
  },
  {
    Header: "LastName",
    accessor: "LastName",
  },
  {
    Header: "Gender",
    accessor: "Gender",
  },
  {
    Header: "DateOfBirth",
    accessor: row =>
      row.DateOfBirth ? FormatDateDDMMMYYYY(row.DateOfBirth) : "",
  },
  {
    Header: "StartDate",
    accessor: row => (row.StartDate ? FormatDateDDMMMYYYY(row.StartDate) : ""),
  },
  {
    Header: "EndDate",
    accessor: row => (row.EndDate ? FormatDateDDMMMYYYY(row.EndDate) : ""),
  },
  {
    Header: "CountryName",
    accessor: "CountryName",
  },
  {
    Header: "StateName",
    accessor: "StateName",
  },
  {
    Header: "CityName",
    accessor: "CityName",
  },
  {
    Header: "Address",
    accessor: "Address",
  },
  {
    Header: "ZipCode",
    accessor: "ZipCode",
  },
  {
    Header: "MealType",
    accessor: "MealType",
  },
  {
    Header: "Date",
    accessor: row =>
      row.HappeningDate ? FormatDateDDMMMYYYY(row.HappeningDate) : "",
  },
  {
    Header: "Class",
    accessor: "ClassTitle",
  },
  {
    Header: "Check In",
    accessor: "CheckIn",
  },
  {
    Header: "Check Out",
    accessor: "CheckOut",
  },
]

const StaffDailyColumnsForExport = [
  {
    Header: "FirstName",
    accessor: "FirstName",
  },
  {
    Header: "LastName",
    accessor: "LastName",
  },
  {
    Header: "Gender",
    accessor: "Gender",
  },
  {
    Header: "DateOfBirth",
    accessor: row =>
      row.DateOfBirth ? FormatDateDDMMMYYYY(row.DateOfBirth) : "",
  },
  {
    Header: "StartDate",
    accessor: row => (row.StartDate ? FormatDateDDMMMYYYY(row.StartDate) : ""),
  },
  {
    Header: "EndDate",
    accessor: row => (row.EndDate ? FormatDateDDMMMYYYY(row.EndDate) : ""),
  },
  {
    Header: "CountryName",
    accessor: "CountryName",
  },
  {
    Header: "StateName",
    accessor: "StateName",
  },
  {
    Header: "CityName",
    accessor: "CityName",
  },
  {
    Header: "Address",
    accessor: "Address",
  },
  {
    Header: "ZipCode",
    accessor: "ZipCode",
  },
  {
    Header: "Role",
    accessor: "RoleType",
  },
  {
    Header: "Class",
    accessor: "ClassTitle",
  },
  {
    Header: "Check In",
    accessor: "CheckIn",
  },
  {
    Header: "Check Out",
    accessor: "CheckOut",
  },
]

const ChildrenAttendanceColumnsForExport = [
  {
    Header: "FirstName",
    accessor: "FirstName",
  },
  {
    Header: "LastName",
    accessor: "LastName",
  },
  {
    Header: "Gender",
    accessor: "Gender",
  },
  {
    Header: "DateOfBirth",
    accessor: row =>
      row.DateOfBirth ? FormatDateDDMMMYYYY(row.DateOfBirth) : "",
  },
  {
    Header: "StartDate",
    accessor: row => (row.StartDate ? FormatDateDDMMMYYYY(row.StartDate) : ""),
  },
  {
    Header: "EndDate",
    accessor: row => (row.EndDate ? FormatDateDDMMMYYYY(row.EndDate) : ""),
  },
  {
    Header: "CountryName",
    accessor: "CountryName",
  },
  {
    Header: "StateName",
    accessor: "StateName",
  },
  {
    Header: "CityName",
    accessor: "CityName",
  },
  {
    Header: "Address",
    accessor: "Address",
  },
  {
    Header: "ZipCode",
    accessor: "ZipCode",
  },
  {
    Header: "MealType",
    accessor: "MealType",
  },
  {
    Header: "Date",
    accessor: row =>
      row.HappeningDate ? FormatDateDDMMMYYYY(row.HappeningDate) : "",
  },
  {
    Header: "Check In",
    accessor: "CheckIn",
  },
  {
    Header: "Check Out",
    accessor: "CheckOut",
  },
]

const ChildrenAllergiesColumnsForExport = [
  {
    Header: "FirstName",
    accessor: "FirstName",
  },
  {
    Header: "LastName",
    accessor: "LastName",
  },
  {
    Header: "Gender",
    accessor: "Gender",
  },
  {
    Header: "DateOfBirth",
    accessor: row =>
      row.DateOfBirth ? FormatDateDDMMMYYYY(row.DateOfBirth) : "",
  },
  {
    Header: "StartDate",
    accessor: row => (row.StartDate ? FormatDateDDMMMYYYY(row.StartDate) : ""),
  },
  {
    Header: "EndDate",
    accessor: row => (row.EndDate ? FormatDateDDMMMYYYY(row.EndDate) : ""),
  },
  {
    Header: "CountryName",
    accessor: "CountryName",
  },
  {
    Header: "StateName",
    accessor: "StateName",
  },
  {
    Header: "CityName",
    accessor: "CityName",
  },
  {
    Header: "Address",
    accessor: "Address",
  },
  {
    Header: "ZipCode",
    accessor: "ZipCode",
  },
  {
    Header: "MealType",
    accessor: "MealType",
  },
  {
    Header: "Home Class",
    accessor: "HomeClass",
  },
  {
    Header: "Allergy Tags",
    accessor: row => {
      // Parse the JSON and map the items
      const tags = row.Tags ? JSON.parse(row.Tags) : []
      return tags.map(item => item.Title).join(", ") // Join the titles as a string (comma-separated)
    },
  },
  {
    Header: "Primary Contacts",
    accessor: row => {
      const contacts = row.PrimaryContacts
        ? JSON.parse(row.PrimaryContacts)
        : []
      return contacts.map(item => `${item.Name} - ${item.Relation}`).join(", ") // Format as "Name - Relation"
    },
  },
  {
    Header: "Emergency Contacts",
    accessor: row => {
      const emergencyContacts = row.EmergencyContacts
        ? JSON.parse(row.EmergencyContacts)
        : []
      return emergencyContacts
        .map(item => `${item.Name} - ${item.Relation}`)
        .join(", ") // Format as "Name - Relation"
    },
  },
]

const ChildrenEmergencyContactsColumnsForExport = [
  {
    Header: "FirstName",
    accessor: "FirstName",
  },
  {
    Header: "LastName",
    accessor: "LastName",
  },
  {
    Header: "Gender",
    accessor: "Gender",
  },
  {
    Header: "DateOfBirth",
    accessor: row =>
      row.DateOfBirth ? FormatDateDDMMMYYYY(row.DateOfBirth) : "",
  },
  {
    Header: "StartDate",
    accessor: row => (row.StartDate ? FormatDateDDMMMYYYY(row.StartDate) : ""),
  },
  {
    Header: "EndDate",
    accessor: row => (row.EndDate ? FormatDateDDMMMYYYY(row.EndDate) : ""),
  },
  {
    Header: "CountryName",
    accessor: "CountryName",
  },
  {
    Header: "StateName",
    accessor: "StateName",
  },
  {
    Header: "CityName",
    accessor: "CityName",
  },
  {
    Header: "Address",
    accessor: "Address",
  },
  {
    Header: "ZipCode",
    accessor: "ZipCode",
  },
  {
    Header: "MealType",
    accessor: "MealType",
  },
  {
    Header: "Home Class",
    accessor: "HomeClass",
  },
  {
    Header: "Emergency Contact",
    accessor: row => `${row.AdultName} - ${row.Relation}`,
  },
  {
    Header: "Phone",
    accessor: "PhoneNumber",
  },
  {
    Header: "Email",
    accessor: "EmailAddress",
  },
  {
    Header: "Address",
    accessor: "Address",
  },
]

const StaffEmergencyContactsColumnsForExport = [
  {
    Header: "FirstName",
    accessor: "FirstName",
  },
  {
    Header: "LastName",
    accessor: "LastName",
  },
  {
    Header: "Role",
    accessor: "RoleType",
  },
  {
    Header: "DateOfBirth",
    accessor: row =>
      row.DateOfBirth ? FormatDateDDMMMYYYY(row.DateOfBirth) : "",
  },
  {
    Header: "HireDate",
    accessor: row => (row.HireDate ? FormatDateDDMMMYYYY(row.HireDate) : ""),
  },
  {
    Header: "SeparationDate",
    accessor: row =>
      row.SeparationDate ? FormatDateDDMMMYYYY(row.SeparationDate) : "",
  },
  {
    Header: "CountryName",
    accessor: "CountryName",
  },
  {
    Header: "StateName",
    accessor: "StateName",
  },
  {
    Header: "CityName",
    accessor: "CityName",
  },
  {
    Header: "Address",
    accessor: "Address",
  },
  {
    Header: "ZipCode",
    accessor: "ZipCode",
  },
  {
    Header: "MealType",
    accessor: "MealType",
  },
  {
    Header: "Emergency Contact",
    accessor: "ContactName",
  },
  {
    Header: "Emergency Phone",
    accessor: "PhoneNumber",
  },
]

const StaffWeeklyColumnsForExport = [
  {
    Header: "FirstName",
    accessor: "FirstName",
  },
  {
    Header: "LastName",
    accessor: "LastName",
  },
  {
    Header: "Gender",
    accessor: "Gender",
  },
  {
    Header: "DateOfBirth",
    accessor: row =>
      row.DateOfBirth ? FormatDateDDMMMYYYY(row.DateOfBirth) : "",
  },
  {
    Header: "HireDate",
    accessor: row => (row.HireDate ? FormatDateDDMMMYYYY(row.HireDate) : ""),
  },
  {
    Header: "SeparationDate",
    accessor: row =>
      row.SeparationDate ? FormatDateDDMMMYYYY(row.SeparationDate) : "",
  },
  {
    Header: "CountryName",
    accessor: "CountryName",
  },
  {
    Header: "StateName",
    accessor: "StateName",
  },
  {
    Header: "CityName",
    accessor: "CityName",
  },
  {
    Header: "Address",
    accessor: "Address",
  },
  {
    Header: "ZipCode",
    accessor: "ZipCode",
  },
  {
    Header: "Role",
    accessor: "RoleType",
  },
  {
    Header: "Date",
    accessor: row =>
      row.HappeningDate ? FormatDateDDMMMYYYY(row.HappeningDate) : "",
  },
  {
    Header: "Class",
    accessor: "ClassTitle",
  },
  {
    Header: "Check In",
    accessor: "CheckIn",
  },
  {
    Header: "Check Out",
    accessor: "CheckOut",
  },
]

const AbsentChildrenColumnsForExport = [
  {
    Header: "FirstName",
    accessor: "FirstName",
  },
  {
    Header: "LastName",
    accessor: "LastName",
  },
  {
    Header: "Gender",
    accessor: "Gender",
  },
  {
    Header: "DateOfBirth",
    accessor: row =>
      row.DateOfBirth ? FormatDateDDMMMYYYY(row.DateOfBirth) : "",
  },
  {
    Header: "StartDate",
    accessor: row => (row.StartDate ? FormatDateDDMMMYYYY(row.StartDate) : ""),
  },
  {
    Header: "EndDate",
    accessor: row => (row.EndDate ? FormatDateDDMMMYYYY(row.EndDate) : ""),
  },
  {
    Header: "CountryName",
    accessor: "CountryName",
  },
  {
    Header: "StateName",
    accessor: "StateName",
  },
  {
    Header: "CityName",
    accessor: "CityName",
  },
  {
    Header: "Address",
    accessor: "Address",
  },
  {
    Header: "ZipCode",
    accessor: "ZipCode",
  },
  {
    Header: "MealType",
    accessor: "MealType",
  },
  {
    Header: "Absent Date",
    accessor: row =>
      row.HappeningDate ? FormatDateDDMMMYYYY(row.HappeningDate) : "",
  },
]

const AbsentStaffColumnsForExport = [
  {
    Header: "FirstName",
    accessor: "FirstName",
  },
  {
    Header: "LastName",
    accessor: "LastName",
  },
  {
    Header: "Gender",
    accessor: "Gender",
  },
  {
    Header: "DateOfBirth",
    accessor: row =>
      row.DateOfBirth ? FormatDateDDMMMYYYY(row.DateOfBirth) : "",
  },
  {
    Header: "HireDate",
    accessor: row => (row.HireDate ? FormatDateDDMMMYYYY(row.HireDate) : ""),
  },
  {
    Header: "SeparationDate",
    accessor: row =>
      row.SeparationDate ? FormatDateDDMMMYYYY(row.SeparationDate) : "",
  },
  {
    Header: "CountryName",
    accessor: "CountryName",
  },
  {
    Header: "StateName",
    accessor: "StateName",
  },
  {
    Header: "CityName",
    accessor: "CityName",
  },
  {
    Header: "Address",
    accessor: "Address",
  },
  {
    Header: "ZipCode",
    accessor: "ZipCode",
  },
  {
    Header: "Role",
    accessor: "RoleType",
  },
  {
    Header: "Absent Date",
    accessor: row =>
      row.HappeningDate ? FormatDateDDMMMYYYY(row.HappeningDate) : "",
  },
]

const UnpaidServicesColumnsForExport = [
  {
    Header: "FirstName",
    accessor: "FirstName",
  },
  {
    Header: "LastName",
    accessor: "LastName",
  },
  {
    Header: "Gender",
    accessor: "Gender",
  },
  {
    Header: "DateOfBirth",
    accessor: row =>
      row.DateOfBirth ? FormatDateDDMMMYYYY(row.DateOfBirth) : "",
  },
  {
    Header: "StartDate",
    accessor: row => (row.StartDate ? FormatDateDDMMMYYYY(row.StartDate) : ""),
  },
  {
    Header: "EndDate",
    accessor: row => (row.EndDate ? FormatDateDDMMMYYYY(row.EndDate) : ""),
  },
  {
    Header: "CountryName",
    accessor: "CountryName",
  },
  {
    Header: "StateName",
    accessor: "StateName",
  },
  {
    Header: "CityName",
    accessor: "CityName",
  },
  {
    Header: "Address",
    accessor: "Address",
  },
  {
    Header: "ZipCode",
    accessor: "ZipCode",
  },
  {
    Header: "Adults",
    accessor: row => {
      // Check if PrimaryContacts exists and format Name-Relation for each contact
      if (row.PrimaryContacts) {
        const contacts = JSON.parse(row.PrimaryContacts)
        return contacts
          .map(contact => `${contact.Name} - ${contact.Relation}`)
          .join(", ")
      }
      return "" // Return empty string if no PrimaryContacts exist
    },
  },
  {
    Header: "Adult Wallet",
    accessor: "Wallet",
    Cell: cellProps => {
      return `$${Number(cellProps.value).toFixed(2)}`
    },
  },
  {
    Header: "Past Due",
    accessor: "DueAmount",
    Cell: cellProps => {
      return `$${Number(cellProps.value).toFixed(2)}`
    },
  },
]

const FinanceColumnsForExport = [
  {
    Header: "Created By",
    accessor: row => `${row.ModifiedByName}`,
  },
  {
    Header: "Program Name",
    accessor: "ProgramName", // Directly map the ProgramName to the column
  },
  {
    Header: "Child Name",
    accessor: "ChildName", // Directly map the ChildName to the column
  },
  {
    Header: "Transaction Type",
    accessor: row => {
      // Return "Wallet Load" if the TransactionTypeID is 0, otherwise return empty string
      return row.TransactionTypeID === 0 ? "Wallet Load" : ""
    },
  },
  {
    Header: "Program Details",
    accessor: row => {
      // If ProgramID is negative or 0, return empty string. Otherwise, format the program details
      return row.ProgramID > 0 ? `${row.ProgramName}, ${row.DayID} days` : ""
    },
  },
  {
    Header: "Start-End Date",
    accessor: row => {
      // Return the formatted date range only if ProgramID > 0
      return row.ProgramID > 0
        ? `${Moment(row.StartDate).format("DD MMM Y, hh:mm A")} - ${Moment(
            row.EndDate
          ).format("DD MMM Y, hh:mm A")}`
        : ""
    },
  },
  {
    Header: "Child",
    accessor: "Name",
    Cell: cellProps => {
      return cellProps.value
    },
  },
  {
    Header: "DateOfBirth",
    accessor: row => FormatDateDDMMMYYYY(row.DateOfBirth),
  },
  {
    Header: "Payer",
    accessor: "Payer",
  },
  {
    Header: "Relation",
    accessor: "Relation",
  },
  {
    Header: "Transaction Type",
    accessor: "TransactionType",
  },
  {
    Header: "Payment Method",
    accessor: "PaymentMethod", // Directly map the PaymentMethod to the column
  },
  {
    Header: "Reference Number",
    accessor: row => {
      // Show Reference Number if PaymentMethodID is not 1 or 4
      return row.PaymentMethodID !== 1 && row.PaymentMethodID !== 4
        ? row.ReferenceNumber
        : ""
    },
  },
  {
    Header: "Modified By",
    accessor: "ModifiedByName", // Directly map the ModifiedByName to the column
  },
  {
    Header: "Amount",
    accessor: row => {
      return "$" + Number(row.Amount).toFixed(2)
    },
  },
  {
    Header: "Status",
    accessor: "TransactionStatus",
  },
]

const WalletBalanceColumnsForExport = [
  {
    Header: "Adult",
    accessor: "Name",
  },
  {
    Header: "Wallet Balance",
    accessor: row => {
      return "$" + Number(row.Wallet).toFixed(2)
    },
  },
]

const WalletColumnsForExport = [
  {
    Header: "Transaction Type",
    accessor: "TransactionType",
  },
  {
    Header: "Transactions",
    accessor: row => {
      return Number(row.Transactions) > 0 ? `${Number(row.Transactions)}` : ""
    },
  },
  {
    Header: "Amount",
    accessor: row => {
      return `$${Number(row.Balance).toFixed(2)}`
    },
  },
]

const PaymentColumnsForExport = [
  {
    Header: "Payment",
    accessor: "TransactionType",
  },
  {
    Header: "Transactions",
    accessor: "Transactions",
    Cell: cellProps => {
      return Number(cellProps.value) > 0 ? `${Number(cellProps.value)}` : ""
    },
  },
  {
    Header: "Amount",
    accessor: "Balance",
    Cell: cellProps => {
      return `$${Number(cellProps.value).toFixed(2)}`
    },
  },
]

const ProgramChargesColumnsForExport = [
  {
    Header: "Program",
    accessor: "ProgramName",
  },
  {
    Header: "Service Period",
    accessor: "Period",
  },
  {
    Header: "Child",
    accessor: "Name",
  },
  {
    Header: "Amount",
    accessor: row => {
      return `$${Number(row.Balance).toFixed(2)}`
    },
  },
]

const DocExpColumnsForExport = [
  {
    Header: "User",
    accessor: "Name",
  },
  {
    Header: "User Role",
    accessor: row => {
      const userRoleID = Number(row.UserRoleID)
      return userRoleID === 2 ? "Adult" : userRoleID === 3 ? "Child" : "Staff"
    },
  },
  {
    Header: "Document",
    accessor: "Document",
  },
  {
    Header: "Uploaded",
    accessor: row => `${row.ModifiedByName} - ${row.ModifiedDate}`,
  },
  {
    Header: "Expiration Day",
    accessor: row =>
      row.ExpiryDate ? FormatDateDDMMMYYYY(row.ExpiryDate) : "",
  },
  {
    Header: "Expiration Day Ago",
    accessor: row => (row.ExpireDaysAgo > 0 ? `${row.ExpireDaysAgo} days` : ""),
  },
]

const TagExpChildrenColumnsForExport = [
  {
    Header: "Child",
    accessor: "Name",
  },
  {
    Header: "DateOfBirth",
    accessor: row => FormatDateDDMMMYYYY(row.DateOfBirth),
  },
  {
    Header: "Tag",
    accessor: "Tags",
  },
  {
    Header: "Category",
    accessor: "Category",
  },
  {
    Header: "Added By",
    accessor: row => `${row.ModifiedByName} - ${row.ModifiedDate}`,
  },
  {
    Header: "Expiration Day",
    accessor: row =>
      row.ExpiryDate ? FormatDateDDMMMYYYY(row.ExpiryDate) : "",
  },

  {
    Header: "Expiration Day Ago",
    accessor: row => (row.ExpireDaysAgo > 0 ? `${row.ExpireDaysAgo} days` : ""),
  },
]

const ChargesColumnsForExport = [
  {
    Header: "Name",
    accessor: "Name",
  },
  {
    Header: "ChargeType",
    accessor: "ChargeType",
  },
  {
    Header: "#",
    accessor: row => {
      const dataType =
        row.DataType === 1
          ? "weeks"
          : row.DataType === 2
          ? "hours"
          : row.DataType === 3
          ? "days"
          : "items"
      return `${row.DataCount} ${dataType}`
    },
  },
  {
    Header: "Amount",
    accessor: row => `$${Math.abs(Number(row.Amount)).toFixed(2)}`,
  },
]

const HourlyCareChargesColumnsForExport = [
  {
    Header: "Hourly Care",
    accessor: row => getHoursMinutes(row.DataCount * 60),
  },
  {
    Header: "Service Period",
    accessor: row => {
      return `${Moment(row.StartDate).format("DD MMM Y")}, ${Moment(
        row.StartTime
      ).format("hh:mm A")} - ${Moment(row.EndDate).format(
        "DD MMM Y"
      )}, ${Moment(row.EndTime).format("hh:mm A")}`
    },
  },
  {
    Header: "Child",
    accessor: "Name",
  },
  {
    Header: "Amount",
    accessor: "Amount",
  },
]

const AddonChargesColumnsForExport = [
  {
    Header: "Add-ons",
    accessor: "Title",
  },
  {
    Header: "Service Period",
    accessor: row => {
      return `${Moment(row.StartDate).format("DD MMM Y")} - ${Moment(
        row.EndDate
      ).format("DD MMM Y")}`
    },
  },
  {
    Header: "Program or Visit",
    accessor: "ProgramName",
  },
  {
    Header: "Child",
    accessor: "Name",
  },
  {
    Header: "Amount",
    accessor: row => `$${Number(row.Amount).toFixed(2)}`,
  },
]

const OtherChargesColumnsForExport = [
  {
    Header: "Other Charges",
    accessor: "Title",
  },
  {
    Header: "Service Period",
    accessor: row => {
      return `${Moment(row.StartDate).format("DD MMM Y")} - ${Moment(
        row.EndDate
      ).format("DD MMM Y")}`
    },
  },
  {
    Header: "Program or Visit",
    accessor: "ProgramName",
  },
  {
    Header: "Child",
    accessor: "Name",
  },
  {
    Header: "Amount",
    accessor: row => `$${Number(row.Amount).toFixed(2)}`,
  },
]

const CreditDiscountChargesColumnsForExport = [
  {
    Header: "Credit & Discount",
    accessor: "Title",
  },
  {
    Header: "Service Period",
    accessor: row => {
      return `${Moment(row.StartDate).format("DD MMM Y")} - ${Moment(
        row.EndDate
      ).format("DD MMM Y")}`
    },
  },
  {
    Header: "Program or Visit",
    accessor: "ProgramName",
  },
  {
    Header: "Child",
    accessor: "Name",
  },
  {
    Header: "Amount",
    accessor: row => `$${Math.abs(Number(row.Amount)).toFixed(2)}`,
  },
]

export {
  ChildrenDailyColumnsForExport,
  ChildrenWeeklyColumnsForExport,
  StaffDailyColumnsForExport,
  ChildrenAttendanceColumnsForExport,
  ChildrenAllergiesColumnsForExport,
  ChildrenEmergencyContactsColumnsForExport,
  StaffEmergencyContactsColumnsForExport,
  StaffWeeklyColumnsForExport,
  AbsentChildrenColumnsForExport,
  AbsentStaffColumnsForExport,
  UnpaidServicesColumnsForExport,
  FinanceColumnsForExport,
  WalletBalanceColumnsForExport,
  WalletColumnsForExport,
  PaymentColumnsForExport,
  ProgramChargesColumnsForExport,
  DocExpColumnsForExport,
  TagExpChildrenColumnsForExport,
  ChargesColumnsForExport,
  HourlyCareChargesColumnsForExport,
  AddonChargesColumnsForExport,
  OtherChargesColumnsForExport,
  CreditDiscountChargesColumnsForExport,
}
