import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import { Link, useNavigate } from "react-router-dom"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../../components/Common/TableContainer"
import * as moment from "moment"
import { v4 as uuidv4 } from "uuid"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import DeleteModal from "../../components/Common/DeleteModal"

import {
  getAdults as onGetAdults,
  deleteAdult as onDeleteAdult,
  updateImpersonateUser as onUpdateImpersonateUser,
} from "store/actions"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"

import {
  Badge,
  Button,
  Col,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
} from "reactstrap"
import { getChildImagePath, getAdultImagePath } from "helpers/imageService"
import Spinners from "components/Common/Spinner"
// import { ToastContainer } from "react-toastify"
import ImpersonateModal from "./../../pages/Staff/modal/ImpersonateModal"
import { EnumObjectTypes } from "helpers/enum_helper"
import ProfilePicture from "components/Common/ProfilePicture"

function Adult() {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { adults, loading } = useSelector(state => state.adult)
  const { userprofile } = useSelector(state => state.user)

  const [isLoading, setLoading] = useState(loading)
  const [deleteModal, setDeleteModal] = useState(false)
  const [adult, setAdult] = useState(null)
  const [impersonateModal, setImpersonateModal] = useState(false)
  const { basicSetting } = useSelector(state => state.Layout)
  //meta title
  document.title = "Adults | " +  ( basicSetting?.SiteName || process.env.REACT_APP_NAME)
  useEffect(() => {
    if (adults && !adults.length) {
      dispatch(onGetAdults())
    }
  }, [dispatch, adults])

  const handleAddClick = () => {
    navigate("/adult/new")
  }

  const onClickDelete = arg => {
    setAdult(arg)
    setDeleteModal(true)
  }

  const onClickImpersonate = arg => {
    setAdult(arg)
    setImpersonateModal(true)
  }

  const handleDelete = () => {
    if (adult && adult.AdultID) {
      dispatch(onDeleteAdult(adult.AdultID))
      setDeleteModal(false)
    }
  }

  const handleImpersonateUser = () => {
    if (adult && adult.UserID) {
      dispatch(onUpdateImpersonateUser(adult.UserID))
      setImpersonateModal(false)
    }
  }

  const handleValidDate = date => {
    const date1 = moment(new Date(date)).format("DD MMM Y")
    return date1
  }

  const AdultStatus = ({ status }) => {
    switch (status) {
      case true:
        return <Badge className="bg-success"> Active </Badge>
      case false:
        return <Badge className="bg-danger"> Inactive </Badge>
      default:
        return ""
    }
  }
  let idCounter = 0

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "Name",
        Cell: cellProps => {
          return (
            <ProfilePicture
              ObjectTypeID={EnumObjectTypes.Adult}
              RowID={cellProps.row.original.RowID}
              Title={cellProps.row.original.Name}
            />
          )
        },
      },
      {
        Header: "Children",
        accessor: "Children",
        Cell: cellProps => {
          return (
            <div className="avatar-group">
              {cellProps?.value?.map((ch, key) => {
                idCounter++ // Increment the counter for each iteration
                const linkId = `childTooltip_${idCounter}` // Generate unique ID for Link
                const tooltipId = `childTooltip_${idCounter}` // Generate unique ID for UncontrolledTooltip
                return (
                  <div className="avatar-group-item" key={key}>
                    <Link
                      to="#"
                      id={linkId}
                      className="team-member d-inline-block"
                    >
                      <img
                        src={getChildImagePath(ch.RowID, "image.jpeg")}
                        className="rounded-circle avatar-xs"
                        alt=""
                      />
                      <UncontrolledTooltip placement="top" target={tooltipId}>
                        {ch.Name}
                      </UncontrolledTooltip>
                    </Link>
                  </div>
                )
              })}
            </div>
          )
        },
      },
      {
        Header: "Email",
        accessor: "Email",
        Cell: cellProps => {
          return cellProps.value ? cellProps.value : ""
        },
      },
      {
        Header: "Created By",
        accessor: "CreatedBy",
        Cell: cellProps => {
          return (
            <>
              <span className="font-size-14 mb-1">
                {cellProps.row.original.CreatedByName}
              </span>
              <p className="text-muted mb-0">
                {handleValidDate(cellProps.row.original.CreatedDate)}
              </p>
            </>
          )
        },
      },
      {
        Header: "Status",
        accessor: "IsActive",
        Cell: cellProps => {
          return <AdultStatus status={cellProps.value} />
        },
      },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-primary"
                onClick={() => {
                  onClickImpersonate(cellProps.row.original)
                }}
                style={{
                  display:
                    userprofile.Impersonate ||
                    cellProps.row.original.UserID == userprofile.UserID
                      ? "none"
                      : "inline-block",
                }}
              >
                <i
                  className="bx bx-log-in-circle font-size-18"
                  id="impersonatetooltip"
                />
                <UncontrolledTooltip
                  placement="top"
                  target="impersonatetooltip"
                >
                  Impersonate
                </UncontrolledTooltip>
              </Link>
              <Link
                to={"/adult/" + cellProps.row.original.RowID}
                className="text-secondary"
              >
                <i className="bx bx-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  onClickDelete(cellProps.row.original)
                }}
              >
                <i className="bx bx-trash font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )
  return (
    <React.Fragment>
      {deleteModal && (
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDelete}
          onCloseClick={() => setDeleteModal(false)}
        />
      )}
      {impersonateModal && (
        <ImpersonateModal
          show={impersonateModal}
          text="You want to impersonate this user?"
          onSubmitClick={handleImpersonateUser}
          onCloseClick={() => setImpersonateModal(false)}
        />
      )}
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Adults" breadcrumbItem="Adult List" />
          {isLoading ? (
            <Spinners setLoading={setLoading} />
          ) : (
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <TableContainer
                      columns={columns}
                      data={adults}
                      isGlobalFilter={true}
                      isAddOption={true}
                      addOptionText="Add adult"
                      handleAddClick={handleAddClick}
                      customPageSize={10}
                      isPagination={true}
                      filterable={false}
                      iscustomPageSizeOptions={true}
                      tableClass="align-middle table-nowrap table-check"
                      theadClass="table-light"
                      pagination="pagination pagination-rounded mb-0 ms-auto"
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </div>
      </div>
      {/* <ToastContainer /> */}
    </React.Fragment>
  )
}
Adult.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default Adult
