import React, { useEffect, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css"

import {
  getAdultDetail as onGetAdultDetail,
  getWalletTransaction as onGetWalletTransaction,
} from "store/actions"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"

import { Button, Col, Row } from "reactstrap"
import { useDeepCompareEffect } from "hooks"
import NotesModal from "./../modal/NotesModal"
import WalletModal from "./../modal/WalletModal"
import Relations from "./../misc/Relations"
import Notes from "pages/Staff/misc/Notes"
import { isEmpty } from "lodash"
import { getAdultVisibility } from "helpers/token_helper"

function FamilyProfile({ rowId }) {
  const dispatch = useDispatch()
  const routeParams = useParams()
  const [notesModal, setNotesModal] = useState(false)
  const [walletModal, setWalletModal] = useState(false)
  const [revealed, setRevealed] = useState(false)
  const [editable, setEditable] = useState(true)
  const { basicSetting } = useSelector(state => state.Layout)

  useDeepCompareEffect(() => {
    const { id } = routeParams
    if (!id) {
      //meta title
      document.title =
        "Family | " +
        (basicSetting?.SiteName || process.env.REACT_APP_NAME)
    }
    if (getAdultVisibility()) {
      setEditable(false)
    }
    dispatch(onGetAdultDetail(rowId))
  }, [dispatch, basicSetting ])

  //////////////////Adult STATE/////////////////////
  const { adult } = useSelector(state => state.adult)
  //////////////////////////////////////

  useEffect(() => {
    if (!isEmpty(adult)) {
      dispatch(onGetWalletTransaction(adult.AdultID))
    }
  }, [adult])

  //////////////////Wallet STATE/////////////////////
  const { wallet } = useSelector(state => state.wallet)
  //////////////////////////////////////

  const onWalletModalClick = () => {
    setWalletModal(true)
  }

  const onNotesModalClick = () => {
    setNotesModal(true)
  }

  const toggleReveal = () => {
    setRevealed(!revealed)
  }

  const onCloseModal = () => {
    setNotesModal(false)
    setWalletModal(false)
  }

  return (
    <React.Fragment>
      {!isEmpty(adult) ? (
        <>
          {notesModal && (
            <NotesModal
              show={notesModal}
              onCloseClick={() => onCloseModal()}
              adultID={adult.AdultID}
              profile={adult}
            />
          )}
          {walletModal && (
            <WalletModal
              show={walletModal}
              onCloseClick={() => onCloseModal()}
              adultID={adult.AdultID}
            />
          )}
        </>
      ) : (
        ""
      )}
      <Row>
        <Col className="col-3">
          <div className="p-3 rounded border h-100">
            <div className="p-3 border-bottom">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <div>
                  <h5 className="m-0 pt-2">Finance</h5>
                </div>
                <div>
                  <Button
                    hidden={!editable}
                    className="btn btn-light waves-effect btn btn-light btn-sm"
                    onClick={() => onWalletModalClick()}
                  >
                    Load Wallet
                  </Button>
                </div>
              </div>
              <div className="d-flex justify-content-between align-items-center mb-3">
                <div>
                  <h6 className="m-0 pt-4">Wallet balance</h6>
                </div>
                <div className="m-0 pt-4">
                  {isEmpty(wallet)
                    ? "$0.00"
                    : "$" + Number(wallet.Balance).toFixed(2)}
                </div>
              </div>
            </div>
            <div className="p-3 border-bottom" hidden={true}>
              <div className="d-flex justify-content-between align-items-center mb-3">
                <div>
                  <h5 className="m-0 pt-2">Security</h5>
                </div>
                <div></div>
              </div>
              <div className="d-flex justify-content-between align-items-center mb-3">
                <div>
                  <h6 className="m-0 pt-4">Kiosk pin</h6>
                </div>
                <div className="m-0 pt-4">
                  {!revealed && (
                    <Button
                      onClick={toggleReveal}
                      className="btn btn-light btn-sm"
                    >
                      Click to {revealed ? "hide" : "reveal"}
                    </Button>
                  )}
                  {revealed && <div className="">{adult.SecurityPin}</div>}
                </div>
              </div>
            </div>
            <div className="p-3 border-bottom">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <div>
                  <h5 className="m-0 pt-2">Notes</h5>
                </div>
                <div>
                  <Button
                    hidden={!editable}
                    className="btn btn-light waves-effect btn btn-light btn-sm"
                    onClick={() => onNotesModalClick()}
                  >
                    Edit
                  </Button>
                </div>
              </div>
              <Notes profile={adult} />
            </div>
          </div>
        </Col>
        <Col className="col-9  p-3 rounded border h-100">
          {!isEmpty(adult) && <Relations />}
        </Col>
      </Row>
    </React.Fragment>
  )
}
export default FamilyProfile
