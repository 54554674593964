import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import { Link, useNavigate } from "react-router-dom"
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../../../components/Common/TableContainer"
import * as moment from "moment"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"

import {
  Badge,
  Button,
  Col,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
} from "reactstrap"

import Spinners from "components/Common/Spinner"
// import { ToastContainer } from "react-toastify"

import { getPaidFeeTransaction as onGetPaidFeeTransaction } from "store/actions"
import MixedPaymentModal from "../modal/MixedPaymentModal"

function MoneyTab() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [mixedModal, setMixedModal] = useState(false)

  const { wallettransactions, loading } = useSelector(state => state.wallet)
  //const { basicSetting } = useSelector(state => state.Layout)
  //meta title
  //document.title ="Money | " + (basicSetting?.SiteName || process.env.REACT_APP_NAME)
  const [isLoading, setLoading] = useState(loading)

  const handleValidDate = date => {
    return moment(new Date(date)).format("DD MMM Y, hh:mm A")
  }

  const onMixedModalClick = e => {
    dispatch(onGetPaidFeeTransaction(e))
    setMixedModal(true)
  }

  const onCloseModal = () => {
    setMixedModal(false)
  }

  const columns = useMemo(
    () => [
      {
        Header: "Created By",
        accessor: row => `${row.ModifiedByName} - ${row.ModifiedDate}`,
        Cell: cellProps => {
          return (
            <>
              <p className="text-muted mb-0">
                {handleValidDate(cellProps.row.original.ModifiedDate)}
              </p>
              <div className="font-size-12 mb-1">
                {"by " + cellProps.row.original.ModifiedByName}
              </div>
            </>
          )
        },
      },
      {
        Header: "Transaction",
        accessor: row => `${row.ProgramName} - ${row.ChildName}`,
        Cell: cellProps => {
          return (
            <>
              <b>{cellProps.row.original.ChildName}</b>
              <p
                className="font-size-12 mb-1"
                hidden={cellProps.row.original.TransactionTypeID > 1}
              >
                Wallet Load
              </p>
              <p
                className="font-size-12 mb-1"
                hidden={cellProps.row.original.ProgramID >= 0}
              >
                {cellProps.row.original.ProgramName}{" "}
                <Link
                  to="#"
                  className="text-success"
                  onClick={() => {
                    onMixedModalClick(cellProps.row.original.FeeTransactionID)
                  }}
                >
                  <i
                    className="mdi mdi-eye-outline font-size-18"
                    id="deletetooltip"
                  />
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    View Detail
                  </UncontrolledTooltip>
                </Link>
              </p>
              <p
                className="font-size-12 mb-1"
                hidden={cellProps.row.original.ProgramID <= 0}
              >
                {cellProps.row.original.ProgramName +
                  ", " +
                  cellProps.row.original.DayID +
                  " days"}
                <div className="font-size-10 mb-1">
                  {moment(cellProps.row.original.StartDate).format(
                    "DD MMM Y, hh:mm A"
                  )}
                  {" - "}
                  {moment(cellProps.row.original.EndDate).format(
                    "DD MMM Y, hh:mm A"
                  )}
                </div>
              </p>
            </>
          )
        },
      },
      {
        Header: "Transaction Type",
        accessor: "TransactionType",
        Cell: cellProps => {
          return cellProps.value ? cellProps.value : ""
        },
      },
      {
        Header: "Payment method",
        accessor: "PaymentMethod",
        Cell: cellProps => {
          return (
            <>
              <span>{cellProps.row.original.PaymentMethod}</span>
              <p
                hidden={
                  cellProps.row.original.PaymentMethodID === 1 ||
                  cellProps.row.original.PaymentMethodID === 4
                }
                className="font-size-10 mb-1"
              >
                {"Ref No. " + cellProps.row.original.ReferenceNumber}
              </p>
            </>
          )
        },
      },
      {
        Header: "Amount",
        accessor: "Amount",
        Cell: cellProps => {
          return cellProps.row.original.PaymentMethodID === 4
            ? "-$" + Number(cellProps.value).toFixed(2)
            : "$" + Number(cellProps.value).toFixed(2)
        },
      },
      {
        Header: "Status",
        accessor: "TransactionStatus",
        Cell: cellProps => {
          return cellProps.value ? cellProps.value : ""
        },
      },
    ],
    []
  )
  return (
    <React.Fragment>
      {mixedModal && (
        <MixedPaymentModal
          show={mixedModal}
          onCloseClick={() => onCloseModal()}
        />
      )}
      {isLoading ? (
        <Spinners setLoading={setLoading} />
      ) : (
        <Row>
          <Col xs="12">
            <Card>
              <CardBody>
                <TableContainer
                  columns={columns}
                  data={wallettransactions}
                  isGlobalFilter={true}
                  isAddOption={false}
                  customPageSize={10}
                  isPagination={true}
                  filterable={true}
                  iscustomPageSizeOptions={true}
                  tableClass="align-middle table-nowrap table-check"
                  theadClass="table-light"
                  pagination="pagination pagination-rounded mb-0 ms-auto"
                  isExportOption={true}
                  headerTitle="Money"
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
      {/* <ToastContainer /> */}
    </React.Fragment>
  )
}
MoneyTab.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default MoneyTab
