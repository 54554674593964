import PropTypes from "prop-types"
import React from "react"
import { connect } from "react-redux"
import withRouter from "components/Common/withRouter"

//i18n
import { withTranslation } from "react-i18next"
import SidebarContent from "./SidebarContent"
//redux
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"

const Sidebar = props => {
  //////////////////BASIC SETTING STATE/////////////////////
  const { basicSetting } = useSelector(state => state.Layout)
  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div className="navbar-brand-box">
          <Link to="/" className="logo logo-dark">
            <span className="logo-sm">
              <img
                src={
                  basicSetting && basicSetting?.SmallDarkFileName
                    ? `${process.env.REACT_APP_API_ROOT_URL}/_files/Theme/${basicSetting?.SmallDarkFileName}`
                    : process.env.REACT_APP_SMALL_LOGO_DARK
                }
                alt=""
                height="22"
              />
            </span>
            <span className="logo-lg">
              <img
                src={
                  basicSetting && basicSetting?.DarkLogoFileName
                    ? `${process.env.REACT_APP_API_ROOT_URL}/_files/Theme/${basicSetting?.DarkLogoFileName}`
                    : process.env.REACT_APP_LOGO_DARK
                }
                alt=""
                height="50"
              />
            </span>
          </Link>

          <Link to="/" className="logo logo-light">
            <span className="logo-sm">
              <img
                src={
                  basicSetting && basicSetting?.SmallLightFileName
                    ? `${process.env.REACT_APP_API_ROOT_URL}/_files/Theme/${basicSetting?.SmallLightFileName}`
                    : process.env.REACT_APP_SMALL_LOGO_LIGHT
                }
                alt=""
                height="50"
              />
            </span>
            <span className="logo-lg">
              <img
                src={
                  basicSetting && basicSetting?.LightLogoFileName
                    ? `${process.env.REACT_APP_API_ROOT_URL}/_files/Theme/${basicSetting?.LightLogoFileName}`
                    : process.env.REACT_APP_LOGO_LIGHT
                }
                alt=""
                height="50"
              />
            </span>
          </Link>
        </div>
        <div data-simplebar className="h-100">
          {props.type !== "condensed" ? <SidebarContent /> : <SidebarContent />}
        </div>
        <div className="sidebar-background"></div>
      </div>
    </React.Fragment>
  )
}

Sidebar.propTypes = {
  type: PropTypes.string,
}

const mapStatetoProps = state => {
  return {
    layout: state.Layout,
  }
}
export default connect(
  mapStatetoProps,
  {}
)(withRouter(withTranslation()(Sidebar)))
